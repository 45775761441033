import { useRef, useEffect, useState } from 'react'
import { Box, Container, Grid, Button, Typography, Badge } from '@mui/material'
import { IcoDashboard } from './ui/icons'
import CircularProgress from '@mui/material/CircularProgress'
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined'
import ModeStandbyOutlinedIcon from '@mui/icons-material/ModeStandbyOutlined'
import Title from './ui/Title'
import EnabledVoices from './EnabledVoices/EnabledVoices'
import ModerationTool from './ModerationTool/ModerationTool'
import AlertSettings from './AlertSettings/AlertSettings'
import MessageQueue from './MessageQueue/MessageQueue'
import * as signalR from '@microsoft/signalr'
import Footer from './ui/Footer'
import { useUser } from '../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import querystring from 'query-string'
import {
  fetchSendPauseAction,
  fetchSendSkipAction,
  fetchSendResumeAction,
  fetchStreamConnect,
  fetchStreamDisconnect
} from '../data/apiService'

const Dashboard = () => {
  const { setUser, user } = useUser()
  const [isModerator, setIsModerator] = useState()
  const [isConnect, setIsConnect] = useState(false)
  const messageQueueRef = useRef()
  const navigate = useNavigate()
 const API_URL = 'https://ecotts-api-cszie3gjba-uc.a.run.app'
//const API_URL = 'http://localhost:5161'

  useEffect(() => {
    if (user && user.id) {
      setIsModerator(user.userType === 'Moderator')
    } else {
      const getToken = async () => {
        try {
          const params = querystring.parse(window.location.search)
          const code = params.code
          const apiResponse = await axios.post(`${API_URL}/api/auth/twitch/code`, { code })
          const token = apiResponse.data

          const userResponse = await axios.post(`${API_URL}/api/auth/twitch/token`, token)
          const userData = userResponse.data
          console.log('userData', userData)

          const userInfoResponse = await axios.post(`${API_URL}/login`, token)
          const userInfo = userInfoResponse.data
          const newUser = {
            id: userData.id,
            login: userData.login,
            displayName: userData.displayName,
            profileImageUrl: userData.profileImageUrl,
            userType: userInfo.userType,
            accessToken: token.accessToken,
            refreshToken: token.refreshToken,
          }
          setUser(newUser)
          navigate('/dashboard')
        } catch (error) {
          console.error('Error en el callback de Twitch:', error)
        }
      }
      
      getToken()
    }
  }, [navigate, user])

  const streamConnect = async () => {
    try {
      await fetchStreamConnect(user)
      setIsConnect(true)
      console.log('hecho streamConnect')
    } catch (error) {
      console.error(error)
    }
  }

  const streamDisconnect = async () => {
    try {
      await fetchStreamDisconnect(user)
      setIsConnect(false)
      console.log('hecho fetchStreamDisconnect')
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!user || !user.accessToken) return

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${API_URL}/myhub`, {
        accessTokenFactory: () => JSON.stringify(user),
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build()

    connection
      .start()
      .then(() => console.log('Conectado al hub'))
      .catch((err) => console.error('Error al conectar al hub:', err))

    connection.on('ReceiveAudioInfo', (audio) => {
      console.log('Mensaje recibido: ')
      console.log('AUDIO', audio)
      onEnqueue(audio)
    })

    connection.on('PauseAction', (moderatorName) => {
      console.log('PauseAction sent by: ', moderatorName)
      onPause()
    })

    connection.on('SkipAction', (moderatorName) => {
      console.log('SkipAction sent by: ', moderatorName)
      onSkip()
    })

    connection.on('ResumeAction', (moderatorName) => {
      console.log('ResumeAction sent by: ', moderatorName)
      onResume()
    })

    return () => {
      connection.stop()
    }
  }, [setUser, user])

  const onEnqueue = (audioUrl) => {
    messageQueueRef.current.enqueue(audioUrl)
  }
  const onSkip = () => {
    if (isModerator === true) {
      fetchSendSkipAction(user)
      return
    }
    messageQueueRef.current.skip()
  }
  const onPause = () => {
    if (isModerator === true) {
      fetchSendPauseAction(user)
      return
    }
    messageQueueRef.current.pause()
  }
  const onPlayNext = () => {
    messageQueueRef.current.playNext()
  }
  const onStop = () => {
    messageQueueRef.current.stop()
  }
  const onResume = () => {
    if (isModerator === true) {
      fetchSendResumeAction(user)
      return
    }
    messageQueueRef.current.resume()
  }

  if (!user || !user.id) {
    console.log('No hay usuario')
    return (
      <Box
        sx={{
          height: '70vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <CircularProgress sx={{ color: '#85C577' }} />
        <Typography
          variant='h6'
          component='p'
          color='#85C577'
          sx={{ fontWeight: '500', marginTop: '8px' }}
        >
          Loading...
        </Typography>
      </Box>
    )
  }

  return (
    <Container maxWidth='xl'>
      <Grid container sx={{ mb: '36px' }}>
        <Grid
          item
          xs={6}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Title icon={<IcoDashboard />} title='DASHBOARD' />
          <Box>
            <Badge
              color='secondary'
              badgeContent={isConnect ? ' ' : null}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Button
                sx={{ mr: '24px', padding: '15px' }}
                variant='contained'
                color='info'
                startIcon={<ModeStandbyOutlinedIcon />}
                onClick={streamConnect}
                size='large'
              >
                CONNECT
              </Button>
            </Badge>
            <Button
              sx={{ padding: '15px' }}
              variant='contained'
              color='error'
              startIcon={<PowerSettingsNewOutlinedIcon />}
              onClick={streamDisconnect}
              size='large'
            >
              DISCONNECT
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MessageQueue ref={messageQueueRef} />
        </Grid>
        <Grid item xs={6}>
          <AlertSettings />
          <ModerationTool
            onSkip={onSkip}
            onPause={onPause}
            onPlayNext={onPlayNext}
            onStop={onStop}
            onResume={onResume}
          />
        </Grid>
      </Grid>
      <EnabledVoices />
      <Footer />
    </Container>
  )
}

export default Dashboard
