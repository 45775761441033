import { Box, Button, Card, CircularProgress, Stack, TextField, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import Title from '../ui/Title'
import { IcoAlertSettings, IcoBits } from '../ui/icons'
import Switch from '../Switch'
import { useUser } from '../../UserContext/UserContext'
import { fetchConfigBits } from '../../data/apiService'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { fetchStreamer } from '../../data/apiService'
import { useNavigate } from 'react-router-dom'

const AlertSettings = () => {
  const initialSwitchStates = {
    Tips: false,
    Bits: false,
    Resub: false,
    Points: false,
    Extension: false,
  }
  const [switchStates, setSwitchStates] = useState(initialSwitchStates)
  const [bits, setBits] = useState('')
  const [isUpdatingBits, setIsUpdatingBits] = useState(false);
  const { user } = useUser()
  const [streamer, setStreamer] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    fetchStreamer(user).then((data) => {
      setStreamer(data)
    })
  }, [user])

  const handleToggle = (name) => {
    setSwitchStates((prevStates) => ({
      ...prevStates,
      [name]: !prevStates[name],
    }))
  }

  /** *REVISAR ESTOOO */
  const AddConfigBits = async () => {
    setIsUpdatingBits(true);
    try {
      var result = await fetchConfigBits(user, bits)
      if(result.success === true){
        toast.success('Minimum bits updated successfully.', { autoClose: 2000 });
        const data = await fetchStreamer(user)
        setStreamer(data)
      } else {
        toast.error('Error updating minimum bits. Retry again', { autoClose: 2000 });
      }
      setBits('')
    } catch (error) {
      console.log(error)
      toast.error('Error updating minimum bits. Retry again', { autoClose: 2000 })
    } finally {
      setIsUpdatingBits(false);
    }
  }

  return (
    <Card>
      <Box
        sx={{
          paddingBottom: '35px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Title icon={<IcoAlertSettings />} title='Add Bits' />
        <Button
          onClick={AddConfigBits}
          variant='contained'
          color='secondary'
          startIcon={<SaveOutlinedIcon />}
          disabled={isUpdatingBits}
        >
          {isUpdatingBits ? <CircularProgress sx={{ color: 'white' }} size={20} /> : 'Save'}
        </Button>
      </Box>
      <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
          <IcoBits />
          <Typography variant='paragraph01' component='p'>
            Bits ({streamer?.minimumCheer})
          </Typography>
        </Stack>
        <Switch
          isOn={switchStates.Bits}
          onColor='#85C577'
          handleToggle={() => handleToggle('Bits')}
          id='switch-bits'
        />
        <TextField
          type='number'
          size='small'
          label='Amount of Bits for AI TTS'
          value={bits}
          variant='filled'
          required
          onChange={(e) => setBits(e.target.value)}
          sx={{ width: '300px' }}
        />
      </Stack>
    </Card>
  )
}

export default AlertSettings
