import { Box, Button, Card, Checkbox, CircularProgress, FormControlLabel, Grid, Stack } from '@mui/material'
import Title from '../ui/Title'
import { IcoDisabledVoices, IcoEnabledVoices, IcoVoiceAudio } from '../ui/icons'
import { fetchDataAllVoices, fetchDataVoices, updateVoices  } from '../../data/apiService'
import { useState, useEffect } from 'react'
import { useUser } from '../../UserContext/UserContext'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


const EnabledVoices = () => {
  const [voices, setVoices] = useState([])
  const [allVoices, setAllVoices] = useState([])
  const [unavailableVoices, setUnavailableVoices] = useState([])
  const [isUpdatingVoices, setIsUpdatingVoices] = useState(false);
  const { user } = useUser()

  const loadVoices = async () => {
    try {
      const data = await fetchDataVoices(user)
      setVoices(data)
    } catch (error) {
      console.error(error)
    }
  }

  const loadAllVoices = async () => {
    try {
      const data = await fetchDataAllVoices(user)
      setAllVoices(data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    loadVoices()
    loadAllVoices()
  }, [])

  useEffect(() => {
    const unavailable = allVoices.filter((av) => !voices.some((v) => v.name === av.name))
    setUnavailableVoices(unavailable)
  }, [voices, allVoices])

  const handleUpdateVoices = async () => {
    try {
      setIsUpdatingVoices(true);
      const voicesToAdd = allVoices.filter((av) => voices.some((v) => v.code === av.code && v.enabled))
      const voicesToRemove = allVoices.filter((av) => voices.some((v) => v.code === av.code && !v.enabled))
      const data = {
        voicesToAdd: voicesToAdd.map((v) => v.name),
        voicesToRemove: voicesToRemove.map((v) => v.name),
      }
      try {
        const updatedVoices = await updateVoices(user, data);
        if(updatedVoices.success == true){
          toast.success('Voices updated successfully.', { autoClose: 2000 });
        } else {
          toast.error('Failed to update voices. Please try again.', { autoClose: 2000 });
        }
      } catch (error) {
        console.error('Error updating voices:', error);
        toast.error('Failed to update voices. Please try again.', { autoClose: 2000 });
      } finally {
        setIsUpdatingVoices(false);
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleCheckboxChange = (code) => {
    setVoices((prevVoices) => 
      prevVoices.map((v) => 
        v.code === code ? { ...v, enabled: !v.enabled } : v
      )
    );
  };

  return (
    <Card>
      <Box
        sx={{
          paddingBottom: '35px',
        }}
      >
        <Title icon={<IcoEnabledVoices />} title='Available Voices' />
      </Box>
      <Grid container spacing={1}>
        {voices
          .filter((v) => v.code !== 'SoundCue')
          .map((voice) => (
            <Grid item xs={1.5} key={voice.code}>
              <Stack direction='row' alignItems='center'>
                <Box sx={{ cursor: 'pointer' }}>
                  <IcoVoiceAudio />
                </Box>
                <FormControlLabel
                  // disabled={!voice.enabled}
                  color='primary.light'
                  value={voice.code}
                  control={
                    <Checkbox checked={voice.enabled} onChange={() => handleCheckboxChange(voice.code)} />
                  }
                  label={voice.name}
                  labelPlacement='start'
                />
              </Stack>
            </Grid>
          ))}
      </Grid>
      <Box
        sx={{
          py: '35px',
        }}
      >
        <Title icon={<IcoEnabledVoices />} title='Sound Cues' />
      </Box>
      <Grid container spacing={1}>
        {voices
          .filter((v) => v.code === 'SoundCue')
          .map((voice) => (
            <Grid item xs={1.5} key={voice.code}>
              <Stack direction='row' alignItems='center'>
                <Box sx={{ cursor: 'pointer' }}>
                  <IcoVoiceAudio />
                </Box>
                <FormControlLabel
                  // disabled={!voice.enabled}
                  color='primary.light'
                  value={voice.code}
                  control={
                    <Checkbox checked={voice.enabled} onChange={() => handleCheckboxChange(voice.code)} />
                  }
                  label={voice.name}
                  labelPlacement='start'
                />
              </Stack>
            </Grid>
          ))}
      </Grid>
      {unavailableVoices.length > 0 && (
        <>
          <Box
            sx={{
              py: '35px',
            }}
          >
            <Title icon={<IcoDisabledVoices />} title='Unavailable Voices' />
          </Box>
          <Grid container spacing={1}>
            {unavailableVoices.map((voice) => (
              <Grid item xs={1.5} key={voice.code}>
                <Stack direction='row' alignItems='center'>
                  <Box sx={{ cursor: 'pointer' }}>
                    <IcoVoiceAudio />
                  </Box>
                  <FormControlLabel
                    disabled
                    color='primary.light'
                    value={voice.code}
                    control={<Checkbox />}
                    label={voice.name}
                    labelPlacement='start'
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <Grid item xs={6} paddingTop={3}>
        <Button              
          variant='contained' 
          color='secondary'
          onClick={handleUpdateVoices}
          disabled={isUpdatingVoices}
        >
          {isUpdatingVoices ? (<CircularProgress xs={6} sx={{ color: 'white' }} size={20} />) : ('Update Voices')}
        </Button>
      </Grid>
    </Card>
  )
}

export default EnabledVoices
