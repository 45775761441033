import React, { useImperativeHandle, forwardRef, useState, useRef, useEffect } from 'react'
import { useUser } from '../../UserContext/UserContext'

const AudioPlayer = forwardRef((props, ref) => {
  const [queue, setQueue] = useState([])
  const [isPlaying, setIsPlaying] = useState(false)
  const [isPause, setIsPause] = useState(false)
  const { user } = useUser()
  const audioContext = new (window.AudioContext || window.webkitAudioContext)()
  let currentAudio = useRef(null)
  const lastAudio = useRef(null)
  const API_URL = 'https://ecotts-api-cszie3gjba-uc.a.run.app';
  //const API_URL = 'http://localhost:5161';

  useImperativeHandle(ref, () => ({
    cleanQueue() {
      this.stop()
      setQueue([])
      setIsPause(false)
      setIsPlaying(false)
    },

    enqueue(audio) {
      setQueue((prevQueue) => [...prevQueue, audio])
    },

    handleAudioEnd() {
      setIsPlaying(false)
      if (queue.length > 0) {
        this.playNext()
      }
    },

    stop() {
      if(!currentAudio) {
        return
      }
      if (currentAudio.current) {
        currentAudio.current.stop()
      }
      setQueue([])
      setIsPlaying(false)
    },

    pause() {
      if (currentAudio) {
        currentAudio.current.stop()
        audioContext.suspend().then(() => {
          setIsPause(true)
          setIsPlaying(false)
        })
      }
    },

    skip() {
      if (!isPlaying) {
        return
      }
      if (currentAudio) {
        currentAudio.current.stop()
        audioContext.suspend().then(() => {
          cleanQueueByOriginalMessage()
        })
      }
    },

    resume() {
      if (isPause) {
        audioContext.resume()
        setIsPause(false)
        setIsPlaying(false)
        cleanQueueByOriginalMessage()
      }
    },
  }))

  const cleanQueueByOriginalMessage = () => {
    const originalMessage = lastAudio.current.originalMessage
    const newQueue = queue.filter(
      (audio) => audio.originalMessage.text !== originalMessage.text
    )
    setQueue(newQueue)
  }

  const playNext = () => {
    if (queue.length === 0 || isPause) {
      return
    }

    const [audio, ...remainingQueue] = queue
    setIsPlaying(true)

    var nextAudioUrl = '';
    if(audio.ttsMessage.voiceCode === 'SoundCue'){
      nextAudioUrl = `${API_URL}/Queue/${user.displayName}/SoundCue/${audio.ttsMessage.voice}`;
    }else{
      nextAudioUrl = `${API_URL}/Queue/${user.displayName}/history?historyItem=` + audio.historyItemId
    }
    fetch(nextAudioUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        'RefreshToken': user.refreshToken,
      },
    })
      .then((data) => data.arrayBuffer())
      .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
      .then((decodedAudio) => {
        currentAudio.current = audioContext.createBufferSource()
        currentAudio.current.buffer = decodedAudio
        currentAudio.current.connect(audioContext.destination)
        currentAudio.current.start(0)
        lastAudio.current = audio
        currentAudio.current.onended = handleAudioEnd
      })
      .catch((error) => console.error('Error fetching or decoding audio:', error))
    setQueue(remainingQueue)
  }

  const handleAudioEnd = () => {
    setIsPlaying(false)
  }

  useEffect(() => {
    if (queue.length === 0) {
      return
    }
    if (!isPlaying && queue.length > 0) {
      playNext()
    }
  }, [queue, isPlaying, isPause])

  return <div></div>
})

export default AudioPlayer
