import axios from 'axios'

const API_BASE_URL = 'https://ecotts-api-cszie3gjba-uc.a.run.app'
//const API_BASE_URL = 'http://localhost:5161'

/**
 * Fetches queue messages for the given channel name.
 *
 * @param {string} channelName - The name of the channel
 * @return {Array} The list of original messages
 */
const fetchQueueMessages = async (user) => {
  try {
    const response = await fetch(`${API_BASE_URL}/queue/${user.displayName}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        RefreshToken: user.refreshToken,
      },
    })
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const data = await response.json()

    return data // Return the list of original messages
  } catch (error) {
    console.error('There was a problem with your fetch operation:', error)
    throw error
  }
}

export { fetchQueueMessages }

export const fetchDataVoices = async (user) => {
  try {
    const response = await fetch(`${API_BASE_URL}/Streamer/${user.displayName}/voices`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        RefreshToken: user.refreshToken,
      },
    })
    if (!response.ok) {
      if(response.status === 401) {
        localStorage.removeItem('user')
        throw new Error('Unauthorized')
      }
      throw new Error('Network response was not ok')
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('There was a problem with your fetch operation:', error)
    throw error
  }
}

export const fetchDataAllVoices = async (user) => {
  try {
    const response = await fetch(`${API_BASE_URL}/voice`, {
      method: 'GET',
      headers: {
        Authorization: user.accessToken,
        RefreshToken: user.refreshToken,
      },
    })
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('There was a problem with your fetch operation:', error)
    throw error
  }
}

/**
 * Updates the voices for a given channel.
 *
 * @param {Object} user - The user object containing the access token and refresh token.
 * @param {Object} data - The object containing the list of voices to add and remove.
 * @return {Promise} A promise that resolves to the updated voices.
 */
export const updateVoices = async (user, data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        RefreshToken: user.refreshToken,
      },
    };

    const response = await axios.put(
      `${API_BASE_URL}/Streamer/${user.displayName}/voices`,
      data,
      config
    );

    return response.data;
  } catch (error) {
    console.error('There was a problem with your updateVoices operation:', error);
    throw error;
  }
};

export const fetchSendPauseAction = async (user) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/Moderator/action/pause`, null, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        RefreshToken: user.refreshToken,
      },
    })
    if (response.status !== 200) {
      throw new Error('Network response was not ok')
    }
  } catch (error) {
    console.error('There was a problem with your fetch operation:', error)
    throw error
  }
}

export const fetchSendSkipAction = async (user) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/Moderator/action/skip`, null, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        RefreshToken: user.refreshToken,
      },
    })
    if (response.status !== 200) {
      throw new Error('Network response was not ok')
    }
  } catch (error) {
    console.error('There was a problem with your fetch operation:', error)
    throw error
  }
}

export const fetchSendResumeAction = async (user) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/Moderator/action/resume`, null, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        RefreshToken: user.refreshToken,
      },
    })
    if (response.status !== 200) {
      throw new Error('Network response was not ok')
    }
  } catch (error) {
    console.error('There was a problem with your fetch operation:', error)
    throw error
  }
}

export const fetchStreamConnect = async (user) => {
  try {
    const body = {
      streamer: user.displayName,
    }
    const response = await axios.post(`${API_BASE_URL}/Stream/connect`, body, {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        RefreshToken: user.refreshToken,
      },
    })
    if (response.status !== 200) {
      throw new Error('Network response was not ok')
    }
  } catch (error) {
    console.error('There was a problem with your fetch operation fetchStreamConnect:', error)
    throw error
  }
}

export const fetchStreamDisconnect = async (user) => {
  try {
    const channelName = user.displayName

    const config = {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        RefreshToken: user.refreshToken,
      },
    }

    const response = await axios.post(
      `${API_BASE_URL}/Stream/disconnect/${channelName}`,
      null,
      config
    )
    if (response.status !== 200) {
      throw new Error('Network response was not ok')
    }
  } catch (error) {
    console.error(
      'There was a problem with your fetch operation fetchStreamDesconnect:',
      error
    )
    throw error
  }
}

export const fetchAddMessageToQueue = async (user, message) => {
  try {
    const channelName = user.displayName

    const config = {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        RefreshToken: user.refreshToken,
      },
    }

    const body = {
      userName: user.displayName,
      text: message,
    }

    const response = await axios.post(`${API_BASE_URL}/Queue/${channelName}/add`, body, config)

    if (response.status !== 200) {
      throw new Error('Network response was not ok')
    }
  } catch (error) {
    console.error(
      'There was a problem with your fetch operation fetchAddMessageToQueue:',
      error
    )
    throw error
  }
}

export const fetchConfigBits = async (user, minimumCheer) => {
  try {
    const channelName = user.displayName

    const config = {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        RefreshToken: user.refreshToken,
      },

      params: {
        minimumCheer: minimumCheer,
      },
    }

    const response = await axios.put(
      `${API_BASE_URL}/streamer/${channelName}/config`,
      null,
      config
    )
    return response.data
  } catch (error) {
    console.error('There was a problem with your fetch operation fetchConfigBits:', error)
    throw error
  }
}

export const fetchCreateModerator = async (user, moderatorName) => {
  try {
    const channelName = user.displayName

    const config = {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        RefreshToken: user.refreshToken,
      },

      params: {
        moderatorName: moderatorName,
      },
    }

    const response = await axios.post(
      `${API_BASE_URL}/streamer/${channelName}/moderator`,
      null,
      config
    )
    return response.data
  } catch (error) {
    console.error('There was a problem with your fetch operation fetchCreateModerator:', error)
    throw error
  }
}

export const fetchStreamer = async (user) => {
  try {
    const response = await fetch(`${API_BASE_URL}/streamer/${user.displayName}/basic	`, {
      method: 'GET',
      headers: {
        Authorization: user.accessToken,
        RefreshToken: user.refreshToken,
      },
    })
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('There was a problem with your fetch operation:', error)
    throw error
  }
}